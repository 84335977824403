import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import TextLoop from "react-text-loop"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import PriceIncrease from "../components/modals/priceIncrease"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCreditCard,
  faGift,
  faHandHoldingHeart,
  faPaperPlane,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons"

const SESSION_MODAL_KEY = "tawalap-session-ashur-info-modal"

const IndexPage = () => {
  const [modalShow, setModalShow] = React.useState(false)

  React.useEffect(() => {
    // check session if already dismissed ignore showing it
    if (!sessionStorage.getItem(SESSION_MODAL_KEY) && !isItAfterThe10th()) {
      setModalShow(true)
    }
  }, [])

  const hideModal = () => {
    setModalShow(false)
    sessionStorage.setItem(SESSION_MODAL_KEY, true)
  }

  const isItAfterThe10th = () => {
    // check date only show before 10th
    return new Date("12/10/2023").getTime() < new Date().getTime()
  }

  return (
    <Layout>
      <Seo title="Home" />
      <section className="py-5 text-center container mb-5">
        <div className="row py-lg-5 mt-5">
          <div className="col-lg-6 col-md-8 mx-auto">
            <StaticImage
              src="../images/logo.png"
              width={300}
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="tawalap logo"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row text-white">
          <div className="col" style={{ minHeight: "130px" }}>
            <h2 className="text-uppercase">
              <TextLoop springConfig={{ stiffness: 100, damping: 10 }}>
                <span className="h1">Heritage</span>
                <span className="aramaic h1">ܝܪܝܼܬܘܼܬܵܐ</span>
                <span className="h1">Luxury</span>
                <span className="aramaic h1">ܬܲܦܢܝܼܩܵܐ</span>
              </TextLoop>
            </h2>
          </div>
        </div>
      </section>
      <section className="container-fluid bg-charcol-black">
        <div className="container pb-md-4">
          <div className="row">
            <div className="col-12 text-white text-md-center">
              <p className="h4 mt-5 text-uppercase">
                Heritage Scarf Is Out Now
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-white text-md-center">
              <p className="minimal-body text-muted">
                A timeless fashion piece reflecting the beauty and charisma of
                feminine accessories.
              </p>
              <Link
                to="/products"
                className="nav-link"
                style={{ fontSize: 14 }}
                data-cy="latest-collection-link"
              >
                <p className="minimal-body mb-0 text-uppercase">Shop Now</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="container-xxl mt-5 mt-md-0 px-0">
          <div className="row justify-content-center mt-md-3">
            <div className="col-12 col-md-auto px-0">
              <StaticImage
                src="../images/heritage_scarves/image_0_3.jpg"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="Heritage scarf in blue by Tawalap"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bg-radial-center pb-5">
        <div className="container pb-3 pb-md-5">
          <div className="row mb-3 mb-md-5 justify-content-md-center text-md-center">
            <div className="col-12">
              <p className="h4 mt-5 mb-2 text-uppercase">Bestselling pendant</p>
            </div>
            <div className="col-md-7">
              <p className="minimal-body">
                Our flagship Ashur pendant is by far the most attractive option
                for our beloved customers, a powerful symbol containing all of
                our heritage into one jewelry piece with our Nation's name to
                wear and represent our beautiful and rich culture.
              </p>
            </div>
            <div className="col-12 text-md-center">
              <Link
                to="/products/ashur-pendant"
                className="nav-link"
                style={{ fontSize: 14 }}
                data-cy="bestselling-link"
              >
                <p className="minimal-body mb-0 text-uppercase">Shop Now</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="container px-0">
          <div className="row justify-content-end justify-content-md-center">
            <div className="col-12 px-0 col-md-5 order-0 order-md-1">
              <StaticImage
                src="../images/home_page/j_esho_ashur_pendant.jpg"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="J.Esho wearing the large ashur pendant"
                className="img-fluid w-100"
              />
              <div className="px-4 px-md-0 mt-3">
                <p className="h4">Artists</p>
                <p className="minimal-body">
                  It is the go to pendant jewelry for many amazing & talented Artists.
                </p>
              </div>
            </div>
            <div className="col-10 px-0 px-md-3 col-md-6 order-1 order-md-0 mt-md-5 mt-5 me-md-4">
              <StaticImage
                src="../images/ashur_pendant/insta-ashur-1_1.jpg"
                quality={100}
                formats={["AUTO", "WEBP"]}
                alt="Model wearing small ashur pendant"
                className="img-fluid w-100 mt-md-5"
              />
              <div className="px-4 px-md-0 mt-3">
                <p className="h4">Versatile</p>
                <p className="minimal-body">
                  With the different sizes available it is suitable for anyone &
                  for any occasion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bg-charcol-black pb-5">
        <div className="row pb-4">
          <div className="col-12 text-white text-center">
            <p className="h4 mt-5 mb-3 text-uppercase">At Your Service</p>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-start text-white my-5">
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faShippingFast}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">
                Enjoy complimentary worldwide shipping.
              </p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon icon={faGift} className="text-light fa-2x" />
              <p className="minimal-body mt-3">
                The perfect option for any occasion.
              </p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faCreditCard}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">Secure checkout options.</p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faHandHoldingHeart}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">
                Peace of mind, with clean and ethically sourced products.
              </p>
            </div>
            <div className="col-6 col-md-2 text-center my-md-0 my-3">
              <FontAwesomeIcon
                icon={faPaperPlane}
                className="text-light fa-2x"
              />
              <p className="minimal-body mt-3">
                Always happy to assist via{" "}
                <a
                  href="mailto:shlama@tawalap.com"
                  className="text-decoration-none text-white"
                >
                  email
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </section>

      <PriceIncrease
        show={modalShow}
        onHide={hideModal}
        showctabutton={"true"}
      />
    </Layout>
  )
}
export default IndexPage
